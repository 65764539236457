import { loginApi } from "../api/http.config";
import { cognitoConfig } from "../config/aws.config";

export const getTokenFromUrl = () => {
    const hash = window.location.hash;
    const params = hash.substr(1).split("&");

    const res = {};
    params.forEach(item => {
        const arr = item.split("=");
        res[arr[0]] = arr[1];
    });

    return res;
};

export const jumpToLogin = () => {
    // window.location.href =
    // "https://peek-robotics-login.auth.ap-southeast-2.amazoncognito.com/login?client_id=3354nra0m0nd0etdc42asebjvs&response_type=token&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost:3001"
    window.location.href = `${loginApi.url}/login?response_type=token&client_id=${cognitoConfig.ClientId}&redirect_uri=https://develop.roverview.co.nz/`;
};